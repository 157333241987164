import React, { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FormContainer from "../../Reusables/Form/Form";
import { FormCheck, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import Title from "../../Reusables/Title/Title";
import api from "../../../../Axios/Axios";

const OpeningTimesEdit = () => {
  const location = useLocation();

  const openingTimes = useRef(location.state).current;
  const [start, setStart] = useState(openingTimes?.start_at);
  const [end, setEnd] = useState(openingTimes?.end_at);
  const [open, setOpen] = useState(openingTimes?.open ? true : false);

  const [notification, setNotification] = useState({
    title: "",
    message: "",
    status: 1,
  });

  function submit(e) {
    setNotification({});
    e.preventDefault();
    let data = {};
    let errors = [];

    const startIsValid = start.match(/((^[0-9{1}][0-9]):([0-9][0-9]))$/);
    const endIsValid = end.match(/((^[0-9{1}][0-9]):([0-9][0-9]))$/);
    const idIsValid = openingTimes?.id;
    const openIsValid = open === true || open === false;

    if (!startIsValid) {
      errors.push("L'orario d'apertura deve contenere 4 Numeri. Esempio 11:20");
    }

    if (!endIsValid) {
      errors.push(
        "L'orario di chiusura deve contenere 4 Numeri. Esempio 11:20"
      );
    }

    if (idIsValid === null || idIsValid === undefined) {
     
      errors.push(
        "Errore, l'id non è stato definito,riprova aggiornando la pagina"
      );
    }

    if (!openIsValid) {
      errors.push("Errore, Open/close non è stato definito");
     
      }
      
    if (errors.length > 0) {
      setNotification({ title: "Errore", errors: errors, status: 0 });
      setTimeout(() => {
        setNotification({});
      }, 3000);
    } else {
      if (!open) {
        if (
          openingTimes?.id === undefined ||
          openingTimes?.id === null ||
          open === undefined ||
          open === null
        ) {
          setNotification({
            title: "Errore",
            message: "ID e valore di chiusura negozio non definiti ",
            status: 0,
          });
          return;
        }

        data = {
          id: openingTimes?.id,
          open: 0,
        };
      } else {
        if (
          !start ||
          !end ||
          openingTimes?.id === undefined ||
          openingTimes?.id === null ||
          open === undefined ||
          open === null
        ) {
          setNotification({
            title: "Errore",
            message: "Verifica che tutti i dati sono riempiti",
            status: 0,
          });
          return;
        }

        data = {
          id: openingTimes?.id,
          open: 1,
          start,
          end,
        };
      }

      api
        .post("info/updateOpeningTimes", { ...data })
        .then((res) => {
          if (res.data.status === 1) {
            setNotification({ title: "Modificato Con Successo" });
          } else {
            setNotification({
              title: "Errore",
              message: notification.error,
              status: 0,
            });
          }
        })
        .finally(
          setTimeout(() => {
            setNotification({});
          }, 3000)
        );
    }
  }

  return (
    <div>
      {notification.title && (
        <div
          className={`position-fixed top-0 container-fluid rounded-0  ${
            notification?.status === 0
              ? "alert alert-danger"
              : "alert alert-success"
          }`}
        >
          <p className="h4 text-center">{notification?.title}</p>
          <p className="text-center">{notification?.message}</p>
          {notification?.errors &&
            notification.errors.map((e, i) => (
              <>
                <p className="text-center">- {e}</p>
              </>
            ))}
        </div>
      )}
      <Title title={"Modifica l'orario"} />
      <Link className="p-5" to={"/admin/opening-times"}>
        Indietro
      </Link>
      <div className="w-50 m-auto">
        <FormContainer submitBtnText={"Modifica"} onSubmit={(e) => submit(e)}>
          <FormGroup>
            <FormLabel htmlFor="day">Giorno</FormLabel>
            <FormControl
              required
              type="text"
              disabled
              value={openingTimes?.day}
            />
          </FormGroup>

          <FormGroup className="mt-4">
            <FormLabel htmlFor="start">Apertura</FormLabel>
            <FormControl
              required
              type="text"
              name="start"
              id="start"
              value={start}
              onChange={(e) => setStart(e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mt-4 mb-3">
            <FormLabel htmlFor="end">Chiusura</FormLabel>
            <FormControl
              required
              type="text"
              name="end"
              id="end"
              value={end}
              onChange={(e) => setEnd(e.target.value)}
            />
          </FormGroup>

          <FormGroup className="mt-4 mb-3">
            <FormLabel htmlFor="open">
              Vuoi che sia "Aperto" questo giorno?
            </FormLabel>
            <FormCheck
              type="checkbox"
              name="open"
              id="open"
              checked={open}
              onChange={(e) => setOpen(!open)}
            />
            {open ? "si" : "no"}
          </FormGroup>
        </FormContainer>
      </div>
    </div>
  );
};

export default OpeningTimesEdit;
