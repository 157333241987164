import React, { useEffect } from 'react'

export const Notification = ({notification,setNotification}) => {

    useEffect(()=>{
        setTimeout(() => {
            setNotification({})
        }, 3000);

        return ()=>{}
    },[notification,setNotification])

  return (

              <div className={`position-fixed top-0 z-3 container-fluid rounded-0  ${notification?.status === 0 ? "alert alert-danger" : "alert alert-success"}`} onClick={ () => setNotification({})}>
                  <p className='h4 text-center'>{notification?.title}</p>
                  <p className='text-center'>{notification?.message}</p>
                  {notification?.errors && notification.errors.map((e, i) => 
                    <>
                          <p className='text-center'>- {e}</p>
                    </>
                  )  
                }
            </div>
          
  )
}
