import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import api from '../../../../Axios/Axios'
import { getImageSize } from 'react-image-size'
import FileResizer from 'react-image-file-resizer'
import Title from '../../Reusables/Title/Title'
import styles from "./albums.module.css"
import Loading from '../../Reusables/Loading/Loading'


export const EditAlbumMix = () => {
    const location = useLocation()
    const [file,setFile] = useState(location.state?.imgPath)
    const [title,setTitle] = useState(location.state?.title ?? "")
    const [desc, setDesc] = useState(location.state?.desc ?? "")
    const [lastPath,setLastPath] = useState(location.state?.lastPath)
    const [errors,setErrors] = useState(null)
    const [success,setSuccess] = useState("")
    const [notification,setNotification] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [loading,setLoading] = useState(false)
    const navigate = useNavigate()

   async function submit(e) {
     setIsSubmit(true)
     setLoading(true)
      e.preventDefault()
      const id = location?.state?.id
      const isNotLastImage = file !== location.state?.imgPath
      const formData = new FormData()
      if (file === location.state?.imgPath && title === location.state?.title && desc === location.state?.desc ) {
        setIsSubmit(false)
        setLoading(false)
        return 
      }
     
      if (!isNotLastImage &&  (title !== location.state?.title || desc !== location.state?.desc) ) {
        formData.append("id",id)
        formData.append("title",title)
        formData.append("desc",desc)
      }
     
     
      try {
        if (checkForErrors() !== true) {
          setErrors(checkForErrors().toString())
          setLoading(false)
          return
        }
     
        let image = null
        if (isNotLastImage) {
            image = await resizeFile(file)
            formData.append("id",id)
            formData.append("title",title)
            formData.append("desc",desc)
            formData.append("file",image)  
            formData.append("lastPath",lastPath)
        }
        
        api.post("gallery/editMix",formData)
          .then(res => {
            if (res.data.status === 1) {
                setSuccess("Modificato con successo");
               
                setErrors(null)
                setTimeout(() => {
                  window.history.back(-1)
                }, 1000);
            } else {
              setIsSubmit(false)
              setErrors("Errore, non è stato possibile eseguire la richiesta");
              setLoading(false)
            }
          }).catch( setIsSubmit(false))
      } catch (error) {
          setErrors(error);
        setIsSubmit(false)
        setLoading(false)
      }
    }



    useEffect(()=>{
      if (success) {
        const timeout = setTimeout(() => {
            setSuccess("")
        }, 2000);
        return ()=> timeout.unref
      }

    },[success])


    function checkForErrors(){
      let errors = [];
      if (!file) {
       errors.push("L'immagine è obbligatoria");
      }
      if (title && title.length > 25 ) {
        errors.push("Il titolo deve essere di massimo 25 caratteri");
      }

      if (desc && desc.length > 255 ) {
        errors.push("La descrizione deve essere di massimo 255 caratteri");
      }

      if (errors.length > 0) {
        return errors
      }
      return true
    }



   async  function checkImage(input){
    try {
      const image = input.files[0]
      if (image) {
        const AcceptedImageTypes = ["image/jpeg","image/png"]
        const dimension = await getImageSize(URL.createObjectURL(image))
        if (dimension.width <= 0 || dimension.width > dimension.height) {
          throw new Error("Il formato dell'immagine deve essere verticale")
        }
        if (!AcceptedImageTypes.includes(image.type)) {
          throw new Error("Il tipo accettato è jpeg e png")
        }
  
       const maxSize = 20 * 1024 * 1024
  
       if (image.size > maxSize || image.size <= 0) {
          throw new Error("L'immagine deve pesare meno di 20Mb")
       }

       setErrors(null);
       setFile(image)
      }else{
        throw new Error("L'immagine è obbligatoria")
      }
    } catch (error) {
      setErrors(error);
      setFile(null)
      input.value = ""
    }
  }



  // const resizeFile = (file) =>
  // new Promise((resolve) => {
  //   FileResizer.imageFileResizer(
  //     file,
  //     1080,
  //     1920,
  //     "WEBP",
  //     80,
  //     0,
  //     (uri) => {
  //       resolve(uri);
  //     },
  //     "file"
  //   );
  // });

  const resizeFile = (file) =>
  new Promise(async(resolve) => {
    let size = 70
    const dimension = await getImageSize(URL.createObjectURL(file))
    if (dimension) {
      FileResizer.imageFileResizer(
        file,
        dimension.width,
        dimension.height,
        "WEBP",
        size,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    }else{
      throw new Error("Errore, non è stato possibile eseguire la richiesta");
    }
   
  });

  


  return (
    <div>
      {loading && <Loading />}
          <Title title={"Modifica Tattoo"} />
          {errors && <div className=" alert alert-danger">{errors.toString()}</div>}
          {success && <div className=" alert alert-success">{success.toString()}</div>}
          <Link className='p-5' onClick={()=> navigate(-1)}>Indietro</Link>
   
          <div className={`container m-auto w-100 d-lg-flex justify-content-center align-content-center ${styles.input_container}`}>
              <form onSubmit={submit} className="container-sm  ">
                    <div className='mt-4'>
                        <label htmlFor="title" hidden={true} className="fw-bold">Inserire un titolo <small className="fw-light"> (massimo 25 caratteri)</small></label> 
                        <input type="text" hidden={true}  name="title" id="title" maxLength={25} className="form-control form-text w-100 border-2 w-100" onChange={(e)=>setTitle(e.target.value)} value={title} />
                    </div>     
                    <div className='mt-4'>
                        <label htmlFor="desc" hidden={true} className="fw-bold"> Inserire una descrizione  <small className="fw-light" > (massimo 255 caratteri)</small></label>
                      <textarea type="text" hidden={true} name="desc" id="desc" className="form-control w-50 border-2 w-100" maxLength={255} onChange={(e)=>setDesc(e.target.value)} value={desc}></textarea>
                    </div>    
                    <div className='mt-4'>
                      <label htmlFor="file" className="fw-bold">Caricare una foto verticale <span className="text-danger">*</span></label>
                      {file && <div className="w-25"><img src={file} onError={(e)=> e.target.src = URL.createObjectURL(file)} className='mb-3' alt="" /></div>}
                        <input type="file" className="form-control-file d-block  w-100" name="file" id="file" accept='image/jpeg, image/png' onChange={(e)=> checkImage(e.target)} required={file !== location.state?.imgPath}  />
                     
                    </div>
                    <button type="submit" disabled={isSubmit} name={"submit"}  className="btn btn-primary w-50 mt-5 p-2 mb-3" style={{maxWidth:300}}>Aggiungi</button>
              </form>
      </div>
    </div>
  )
}
