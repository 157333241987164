import React, { useEffect, useState } from 'react'

const Modal = ({modalPath,setModalPath}) => {
  const [newImage, setNewImage] = useState(null)
  const [dataIndex,setDataIndex] = useState(0)
  const [isHorizontal,setIsHorizontal] = useState(false)




  useEffect(() => {
    if (modalPath) {
      setNewImage(modalPath?.data[modalPath?.index]?.path)
      setDataIndex(modalPath?.index)
      setIsHorizontal(modalPath?.data[dataIndex]?.type === 0 || modalPath?.data[dataIndex]?.type === 1)
    }
   
  }, [modalPath])


  function showNew(symbol) {
    setDataIndex(symbol === "-" ? dataIndex - 1 : dataIndex + 1)
    if (dataIndex <= 0 && symbol === "-") {
      setDataIndex(modalPath?.data.length - 1)
    }
    if (dataIndex >= modalPath?.data.length - 1 && symbol === "+") {
      setDataIndex(0)
    }
  }

  useEffect(() => {
    setNewImage(modalPath?.data[dataIndex]?.path)
  },[dataIndex,modalPath])
  
  return (
    <section className="modale" id="modale" style={newImage ? {display:"flex"}: {display:"none"}}>
    <div className="modale-container">
        <img 
        src={ !isHorizontal ? "assets/images/uploads/" + modalPath?.albumName + newImage : "assets/images/uploads/studio/" + newImage } 
        className={modalPath?.data[dataIndex]?.type === 0 ? "modal-horz" : "modal-vert"} 
         alt="Studio Tattoo Black Rose, Messina"
         style={{borderRadius:0,objectFit:"contain",maxWidth:!isHorizontal && 1920,height:!isHorizontal && "100vh"}}
         />
        <button id="modale-exit" onClick={()=>setModalPath(null)}>X</button>
        
    </div>

    <div className="modale-prev-img-btn modale-img-btn carousel-control-prev-icon" id="prev-btn" onClick={()=>showNew("-")}></div>
    <div className="modale-next-img-btn modale-img-btn carousel-control-next-icon" id="next-btn" onClick={()=>showNew("+")}></div>
    
    </section>
  )
}

export default Modal
