import React from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '../../../../Axios/Axios'
import Gallery from './Gallery'



const SectionTatuatori = () => {
    
     
  const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["artists"],
    queryFn:() => {
    return api.get("artist/all").then(response => response.data.artists)
    }
  })
    
   
   
    return data && data.length > 0 && (
        <section className="page" id="tatuatori">
            <div className="inner-gallery-page inner-page tatuatori-container">
            <section className="inner-page-text-section padding-140">
                <p className="title-page">TATUATORI</p>
                <h2 className="description-title-page white-rose--left">Gli artisti di Black Rose</h2>
                <hr className="separator-in-page" />
            </section>
            {data?.map((author, index) => 
            <div  key={index}>
                <Gallery
                author={author}
                index={index + 1}
                />
            </div>        
            
        )}

            </div>  
        </section>
      
  )
}

export default SectionTatuatori
