import React from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '../../../../Axios/Axios'
import Title from '../../Reusables/Title/Title'
import { Link, useNavigate } from 'react-router-dom'


const GeneralSetting = () => {
    
    
  const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["generalSettings"],
    queryFn:() => {
    return api.post("info/info").then(response => response.data?.info)
    }
})

    const navigate = useNavigate()
    
  return (
      <div>
          <Title title={"Settaggi Generali"} />
          <Link className='p-5' to={"/admin/home"}>Indietro</Link>
          {isLoading && <p>loading</p>}
          <div className="container-fluid mt-5 container_scroll">
          <table className='table table-bordered table-responsive border-black admin-table table table-striped '>
                    <thead>
                            <tr>
                                <th>ID</th>
                                <th>Email</th>
                                <th>Indirizzo</th>
                                <th>Facebook</th>
                                <th>Instagram</th>
                                <th>Telefono</th>
                                <th>P.IVA</th>
                                <th>Modifica</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                               <td>{data?.id}</td>
                                <td>{data?.email}</td>
                                <td>{data?.address}</td>
                                <td>{data?.facebook}</td>
                                <td>{data?.instagram}</td>
                                <td>{data?.tel}</td>
                                <td>{data?.iva_number}</td>    
                                <td style={{cursor:"pointer"}} onClick={()=>navigate("/admin/general-settings/edit",{state: data}) }><img style={{minWidth:0,width:50}} src='/assets/icons/edit.svg' alt=''/></td>    
                            </tr>
           
                      </tbody>
            </table>
        </div>
      
    </div>
  )
}

export default GeneralSetting
