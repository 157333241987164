import React from 'react'

const CardInfo = ({ tel, instagram, facebook, imagePath
}) => {
  



    return (
      <>
        <div className="artist-social-networks-outer">
        <ul className="artist-social-networks-inner">
          <li className="card-social-network-item">
            <a
            href={`https://api.whatsapp.com/send?phone=39${+tel.toString().replace(/[-\s]/g, '')}`}
              className="card-social-network-item--link card-social-network-item--link-first-whatsapp"
              title="Contattami su Whatsapp!"
            >{}</a>
          </li>
          <li className="card-social-network-item">
            <a
              href={instagram}
              className="card-social-network-item--link card-social-network-item--link-first-instagram"
              title="Visita la mia pagina Instagram"
            >{}</a>
          </li>
          <li className="card-social-network-item">
            <a
              href={facebook}
              className="card-social-network-item--link card-social-network-item--link-first-facebook"
              title="Visita la mia pagina Facebook"
            >{}</a>
          </li>
        </ul>
      </div>
        <div className="artist-card-container outer-figure">
        <figure className="artist-card artist-card--first">
          <img
            className="artist-img artist-img--first"
            src={`/assets/images/uploads/${imagePath}`}
            alt={`Tatuatore presso Black Rose Tattoo`}
            loading='lazy'
          />
        </figure>
      </div>
     </>
  )
}

export default CardInfo
