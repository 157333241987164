import React from 'react'
import { Link } from 'react-router-dom'

export const NotFoundPage = () => {
  return (
    <div className='bg-dark vw-100 vh-100'>
      <Link className='position-fixed top-0 m-3 p-5' to='/'>Indietro</Link>
      <center >
  
        <div className='text-light' style={{fontSize:30,position:"absolute",top:"30%",left:"50%",transform:"translate(-50%,-50%)"}}>404 PAGINA NON TROVATA</div>
      </center>
    </div>
   
  )
}
