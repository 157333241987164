import React, { useState } from 'react'
import FormContainer from '../../../admin/Reusables/Form/Form';
import api from '../../../../Axios/Axios';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap';



const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState(false);
  const navigate = useNavigate()

  

  const onSubmit = (e) => {
    e.preventDefault()
    const formdata = new FormData()
    formdata.append("username",username)
    formdata.append("password",password)
   
    api.post("user/login", formdata).then(res => {
      if (res.data.status === 1) {
        api.defaults.withCredentials = true
        setUsername("")
        setPassword("")
        navigate("/admin/home")
      } else {
     
        setAuthError(true)
        setTimeout(() => {
          setAuthError(false)
        }, 3000);
      }
    })
  }



  return (
    <div className='container'>
      <h1 className='mb-3 m-3 text-center'>Login</h1>
     
      {authError && <p className='bg-danger text-white p-1 text-center'>Username o Password incorretta</p>}
      <FormContainer onSubmit={onSubmit} submitBtnText={"Login"}>

      <FormGroup className="mb-3" >
          <FormLabel htmlFor={"username"}>{"Username"}</FormLabel>
          <FormControl required={true} value={username} onChange={(e)=>setUsername(e.target.value)} type={"text"} name={"username"} placeholder={"Enter username"}  />
        </FormGroup>

       <FormGroup className="mb-3" >
          <FormLabel htmlFor={"password"}>{"Password"}</FormLabel>
          <FormControl required={true} value={password} onChange={(e) => setPassword(e.target.value)} type={"password"} name={"password"} placeholder={"Enter password"}  />
        </FormGroup>  
        
      </FormContainer>

    </div>
  )
}

export default Login
