import React from 'react';
import './FormLoading.css';
import styles from "./notification.module.css"

const FormLoading = ({progress}) => {

    return progress && (
      
         <div class={`toast show ${styles.f_toast}  ${styles.f_toast_errors}`} role="alert" aria-live="assertive" aria-atomic="true" style={{border:"none",zIndex:2,visibility:progress <= 0 && "hidden"}}>
         <div class={`toast-header d-flex justify-content-between ${styles.notification_header} bg-dark`} style={{border:"none"}}>
           <strong class={`mr-auto ${styles.notification_brand_name}`}>Inviando...</strong>
           
         </div>
         <div class="toast-body bg-dark" style={{border:"none"}}>
         <div className="form-loading">
            <div className="progress-bar bg-primary" style={{ width:`${progress}%`}}></div>
        </div>
         </div>
       </div>
    );
};

export default FormLoading;
