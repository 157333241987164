import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import TattooImages from "./TattooImages";
import TattooImg from "../MainCarousel/TattooImg";
import { modalContext } from "../../PublicRoute";
import style from "./carousel.module.css"


const Carousel = ({
  albumId,
  nameInCard,
  tel,
  instagram,
  facebook,
  imagePath,
  isMainCarousel,
  data,
  pressedBtn,
  albumName,
  authorName
}) => {

  const [namePosition, setNamePosition] = useState(null);
  const [mouseIsHover, setMouseIsHover] = useState(false);
  const [mouseStartPosX, setMouseStartPosX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);
  const [imgSize, setImgSize] = useState(null)
  const [isMouving,setIsMouving] = useState(false)
  const nameSplit = useRef(nameInCard?.split(","))?.current;
  let artistName = useRef(null);
  let slider = useRef(null);
  const {setModalPath} = useContext(modalContext)

  useEffect(() => {
    if (window.innerWidth < 680) {
      setNamePosition(artistName?.current?.getBoundingClientRect().x - 50);
    } else {
      setNamePosition(artistName?.current?.getBoundingClientRect().x + 100);
    }
  }, []);
    
    
    

  function mouseDown(e) {
    setMouseStartPosX(e.pageX - slider.current.offsetLeft);
    setScrollLeft(slider?.current?.scrollLeft);
    setMouseIsHover(true);
    }
    


  const mouseUp = useCallback((e) => {
    setMouseIsHover(false);
    
    setTimeout(() => {
      setIsMouving(false)
    }, 300);
  }, []);
    
    

  const mouseMove = useCallback(
    (e) => {
      if (!mouseIsHover) {
        return;
      }
      setIsMouving(true)
      const x = e.pageX - slider.current.offsetLeft;
      
      const walk = (x - mouseStartPosX) * 1;
     
      slider.current.scrollTo({
        left: scrollLeft - walk,
        scrollBehavior: "smooth",
      });
    },
    [mouseIsHover, mouseStartPosX, scrollLeft]
);
    


  useEffect(() => {
   
      if (pressedBtn) {
       
      const direction = ( pressedBtn === "right" ? +imgSize + 15 : -imgSize - 15) ;
      slider.current.scrollBy({
        top: 0,
        left: direction,
        behavior: "smooth",
      });
    }
  }, [pressedBtn,imgSize]);
   

  const scroll = useCallback(() => {
    setIsMouving(true)
    const a = document.querySelectorAll(".img-container--slider-tatuatori");
    a.forEach((el) => {
      if (window.innerWidth > 680) {
        if (
          namePosition > el.getBoundingClientRect().x - 40 &&
          window.innerWidth > 448
        ) {
          el.style.opacity = "0.5";
        } else {
          el.style.opacity = "1";
        }
      } else {
        el.style.opacity = "1";
      }
    });
  }, [namePosition]);
    
    

  useEffect(() => {
    const slide = slider?.current;
    slide.addEventListener("mousedown", mouseDown);

    return () =>{
      slide.removeEventListener("mousedown", mouseDown);
    };
  }, []);
    
    

  useEffect(() => {
    const slide = slider?.current;

    slider?.current.addEventListener("mouseup", mouseUp);

    return () => {
      slide.removeEventListener("mouseup", mouseUp);
    };
  }, [mouseUp]);
    
    

  useEffect(() => {
    const slide = slider?.current;

    slide.addEventListener("mousemove", mouseMove);

    return () => {
      slide.removeEventListener("mousemove", mouseMove);
    };
  }, [mouseMove]);
    
    

  useEffect(() => {
    const slide = slider?.current;
    slide.addEventListener("mouseleave", mouseUp);

    return () =>{
      slide.removeEventListener("mouseleave", mouseUp);
    };
  }, [mouseUp]);
    
    

  useEffect(() => {
    const slide = slider?.current;
    slide.addEventListener("scroll", scroll);
    return () => {
      slide.removeEventListener("scroll", scroll);
    };
  }, [scroll]);
    
    

  return (
    <>
      {!isMainCarousel ? (
        <section className="First-artist-gallery artist-gallery-section  padding-left-140 ">
          <div className="carousel-container-artist-outer carousel-container-artist-outer--first  ">
            <div className="inner-carousel">
              <div className="artist-slider-carousel-container" ref={slider}>
                <p className="artist-card-name" ref={artistName}>
                  {nameSplit[0]?.toUpperCase()}
                  <br />
                  {nameSplit[1]?.toUpperCase()}
                  <br />
                  {nameSplit[2]?.toUpperCase()}
                </p>

                <div className="slider-carousel-inner">
                  <div className="slider outer-figure">
                    <TattooImages
                      isMouving={isMouving}
                      setModalPath={setModalPath}
                      setImgSize={setImgSize}                    
                      mouseIsHover={mouseIsHover}
                      authorInfo={{ tel, instagram, facebook, imagePath,authorName }}
                      namePosition={namePosition}
                      nameInCard={nameInCard}
                      albumId={albumId}
                      albumName={albumName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="carousel-galleria-section">
          <div className="inner-carousel-section" ref={slider}>
            <div className="carousel-container">
              <div className="first-carousel carousel">
                {data.map((img, index) => {
                  return (
                    index % 2 === 0 && (
                      <TattooImg
                      onClick ={()=>{!isMouving && setModalPath({data,index,albumName:"main/"})}}
                        key={index}
                        path={img.path}
                        description={img.description}
                       setImgSize={setImgSize}
                       albumName={albumName}
                      />
                    )
                  );
                })}
              </div>
              <div className="second-carousel carousel">
                {data.map((img, index) => {
                  return (
                    index % 2 === 1 && (
                      <TattooImg
                        onClick ={()=>{ !isMouving && setModalPath({data,index,albumName:"main/"})}}
                        key={index}
                        path={img.path}
                        description={img.desc}
                        setImgSize={setImgSize}
                        albumName={albumName}
                      />
                    )
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Carousel;
