import React, { useState,useContext } from 'react'
import HeaderCarousel from './HeaderCarousel'
import Carousel from './Carousel'

const Gallery = ({ author, index }) => {
    
  const [pressedBtn, setPressedBtn] = useState(null)
  
  const move = (direction) => {
    if (!pressedBtn) {
    
      setPressedBtn(direction)
      setTimeout(() => {
        setPressedBtn(false)
      },300)
    }
   
  }

  return (
    <>
      
      <HeaderCarousel
        index={index}
        name={author?.stage_name}
        move={move}
      />
      
      <Carousel
              pressedBtn={pressedBtn}
              isMainCarousel={false}
              albumName={author.album_name}
              nameInCard={author.name_in_card}
              tel={author.tel}
              instagram={author.instagram}
              facebook={author.facebook}
              imagePath={author.image_path}
              authorName={author.name}
          />
    </>
  )
}

export default Gallery
