import React, {  useState } from 'react'
import {  useQuery } from '@tanstack/react-query'
import api from '../../../../Axios/Axios'
import {Link, useNavigate} from 'react-router-dom'

import Title from '../../Reusables/Title/Title'
import { Notification } from '../../Reusables/Notification'
import Loading from '../../Reusables/Loading/Loading'


const Authors = () => {
    const navigate = useNavigate()
    const [notification, setNotification] = useState({})
    const [loading,setLoading] = useState(false)

const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["artists"],
    queryFn:async () => {
    return api.post("artist/all").then(response => response.data)
    },
    staleTime: 1,
    refetchOnWindowFocus:false
})



 

    function toggleActiveArtist(index, id, isActive) {
        const notification = {
            status: 0,
            title: "",
            message: "",
          };
       
        const active = isActive === 1 ? 0 : 1
        const formData = new FormData()
        formData.append("active",active)
        formData.append("id",id)
        api.post("artist/toggleActive",formData).then(res => {
            if (res.data.status === 1) {
                data.artists[index].active = active;
                if ( active === 0) {
                    notification.status = 1;
                    notification.title = "Disattivato con successo";
                    notification.message = "";
                } else if ( active === 1) {
                   
                    notification.status = 1;
                    notification.title = "Attivato con successo";
                    notification.message = "";
                }
            
            }else{
            
              notification.status = 0;
              notification.title = "Errore";
              notification.message = "durante la modifica dell'artista";
            }
        }).catch(()=>{
            notification.status = 0;
            notification.title = "Errore ";
            notification.message = "durante la modifica dell'artista";
        })
        .finally(res => setNotification(notification))
        
    }

    function deleteArtist(id, albumName, profileImage) {
        setLoading(true)
        const notification = {
            status: 0,
            title: "",
            message: "",
          };
        const confirm = window.confirm("Vuoi eliminare questo Autore e le sue rispettive foto?")
        const formdata = new FormData()
        formdata.append("id",id)
        formdata.append("albumName",albumName)
        formdata.append("profileImage",profileImage)
      
        if (confirm) {
            api.post("artist/delete",formdata).then(res => {
                if (res.data.status === 1) {
                    notification.status = 1;
                    notification.title = "Eliminato con successo";
                    notification.message = "";
                    refetch()
                }else{
                    notification.status = 0;
                    notification.title = "Errore";
                    notification.message = "Errore durante l'eliminazione dell'artista";
                   
                }
            }).catch(()=>  {
            notification.status = 0;
            notification.title = "Errore";
            notification.message = "Errore durante l'eliminazione dell'artista"
            }).finally(res => {
                setNotification(notification)
                setLoading(false)
            })
        }
    }




    
    return (
        <div>
           { loading && <Loading/>}
            <Title title={"Artisti"}/>
            <Link className='p-5' to={"/admin/home"}>Indietro</Link>
            {notification.title && <Notification notification={notification} setNotification={setNotification}/>}   
            <div>
            {(error || !data?.artists) && (
            <div className='fw-bold position-absolute mt-3 alert vw-100 alert-danger text-center '> <span>Non ci sono Artisti registrati</span></div>
            )}
                {isLoading && <p>LOADING</p>}
           <div  className='container_scroll'>
           <table  className=' table table-striped border-black'>
                        <thead className='text-center'>
                              {  data?.artists && <tr>
                                    <th>Nome</th>
                                    <th>Nome d'arte</th>
                                    <th>Nome in Card</th>
                                    <th>Foto</th>
                                    <th>Telefono</th>
                                    <th>Instagram</th>
                                    <th>Facebook</th>
                                    <th>Album</th>
                                    <th>Attivo</th>
                                    <th>Modifica</th>
                                    <th>Elimina</th>
                                </tr>}
                            </thead>

                            <tbody className='text-center'>
                            {data?.artists && data?.artists?.length > 0 && data.artists.map((author, i) => {
                                return (
                                    <tr key={i}>
                                      
                                        <td className='border-1'>{author.name}</td>
                                        <td className='border-1'>{author.stage_name}</td>
                                        <td className='border-1'>{author.name_in_card}</td>
                                        <td className='border-1'>{author.image_path}</td>
                                        <td className='border-1'>{author.tel}</td>
                                        <td className='border-1'>{author.instagram}</td>
                                        <td className='border-1'>{author.facebook}</td>
                                        <td  className='border-1' style={{cursor:"pointer"}} onClick={()=>navigate("/admin/author/album/" + author.id, {state:{albumName:author.album_name,artistName:author.name}})}><img style={{minWidth:0,width:40}} src="/assets/icons/album.svg" alt=""  /></td>
                                        <td className='border-1' style={{ cursor: "pointer" }} onClick={() => toggleActiveArtist(i,author.id,author.active)}><input type="checkbox" checked={author.active} onChange={(e)=>(!e.target.checked)}/></td>
                                        <td className='border-1' style={{cursor:"pointer"}} onClick={()=> navigate(`/admin/author/${author.id}`,{state:author})}><img style={{minWidth:0,width:50}} src="/assets/icons/edit.svg" alt="" /></td>
                                        <td  className='border-1' style={{cursor:"pointer"}} onClick={()=>deleteArtist(author.id,author.album_name,author.image_path)}><img  style={{minWidth:0,width:50}} src="/assets/icons/delete.svg" alt=""  /></td>
                                    </tr>
                                )
                            })
                        
                        }
                        </tbody>
                    </table>
           </div>
               
            </div>
        </div>
    )
}

export default Authors
