import React, { useState,createContext } from "react";
import Header from "./layout/Header";
import Main from "./layout/Main";
import HeroBrand from "./sections/HeroBrand/HeroBrand";
import SectionMainGallery from "./sections/MainCarousel/SectionMainGallery";
import SectionTatuatori from "./sections/Tatuatori/SectionTatuatori";
import SectionStudio from "./sections/Studio/SectionStudio";
import InfoContact from "./sections/Info/InfoContact";
import Modal from "./Modal/Modal";
import Footer from "./layout/Footer";


export const modalContext = createContext()

const PublicRoute = () => {
 
  const [infoFooter, setInfoFooter] = useState(null);
  const [modalPath, setModalPath] = useState(null)
  
  function getInfo(data) {
    setInfoFooter(data);
  }

  return (
    <div className="innerBody">
      <Header tel={infoFooter?.tel} />
      <Main>
        <modalContext.Provider value={{modalPath,setModalPath}}>
        <HeroBrand />
        <SectionMainGallery />
        <SectionTatuatori />
        <SectionStudio />
        <InfoContact getInfo={(data) => getInfo(data)} />
        <Modal modalPath={modalPath} setModalPath = {setModalPath} />
        </modalContext.Provider>
      </Main>

      <Footer infoFooter={infoFooter} />
    </div>
  );
};

export default PublicRoute;
