import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import api from '../../../../Axios/Axios'
import Title from '../../Reusables/Title/Title'
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styles from "./albumMix.module.css"
import { Notification } from '../../Reusables/Notification';
import Loading from '../../Reusables/Loading/Loading';

export const AlbumMix = () => {
  const navigate = useNavigate();
  const [notification,setNotification] = useState({})

  
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["album_mix"],
    queryFn: () => {
      return api
        .post("gallery/mix")
        .then((response) => response.data.mix);
    },
  });

  

  function deleteTattoo(id, lastPath, index) {
   
    try {
      const notification = {
        status: 0,
        title: "",
        message: "",
      };
      const question = window.confirm(
        "Vuoi veramente eliminare questo tattoo?"
      );
      if (question === false) {
        return;
      }
      const formData = new FormData();
      formData.append("id", id);
      formData.append("lastPath", lastPath);
      formData.append("albumName", "main");
      api
        .post("gallery/deleteMix", formData)
        .then((res) => {
          if (res.data.status === 1) {
            notification.status = 1;
            notification.title = "Modifica effettuata";
            notification.message = "";
            delete data[index]
          } else {
            notification.status = 0;
            notification.title = "Errore";
            notification.message ="Impossibile eliminare questo Tattoo";  
          }
        })
        .catch((error) => {
          notification.status = 0;
          notification.title = "Errore";
          notification.message ="Impossibile eliminare questo Tattoo";  
        }).finally(res => setNotification(notification))
    } catch (error) {
      notification.status = 0;
      notification.title = "Errore";
      notification.message ="Impossibile eliminare questo Tattoo";  
    }
  }

 
  return (
    <>
    {isLoading && <Loading />}
      <Title title={`Galleria MIX`} />
      <div className={styles.page_container}>
        <Link className="p-5" to={"/admin/albums"}>
          Indietro
        </Link>
        {((error && !Loading) || (!data && !Loading)) && (
           <div  className='fw-bold position-absolute mt-3  alert w-100 alert-danger text-center'>Non ci sono immagini</div>
        )}
        {notification.title && <Notification notification={notification} setNotification={setNotification}/>}
        <div className={styles.carousel + " d-flex  mt-2 "}>
          {data?.length > 0 &&
            data.map((tattoo, i) => (
              <div key={i} className={`position-relative ${styles.hoverable}`}>
                <div className={styles.icons_container}>
                  <span
                    onClick={() =>
                      navigate("/admin/album/mix/edit", {
                        state: {
                          id: tattoo.id,
                          albumName: "main",
                          title: tattoo.title,
                          desc: tattoo.desc,
                          imgPath:
                            "/assets/images/uploads/" +
                            "main/" +
                            tattoo.path,
                          lastPath: tattoo.path,
                        },
                      })
                    }
                  >
                    <img
                      className={styles.icon}
                      src="/assets/icons/edit.svg"
                      alt=""
                    />
                  </span>
                  <span
                    onClick={() =>
                      deleteTattoo(
                        tattoo.id,
                        tattoo.path,
                        i
                      )
                    }
                  >
                    <img
                      className={styles.icon}
                      src="/assets/icons/delete.svg"
                      alt=""
                    />
                  </span>
                </div>
                <h5
                  className={styles.title}
                  style={
                    tattoo.title && tattoo.title !== "null"
                      ? {}
                      : { display: "none" }
                  }
                >
                  #{tattoo.title}
                </h5>
                <img
                  
                  src={
                    "/assets/images/uploads/" +
                   "main/" +
                    tattoo.path
                  }
                  alt=""
                />
                <p
                  className={styles.desc}
                  style={
                    tattoo.desc && tattoo.desc !== "null"
                      ? {}
                      : { display: "none" }
                  }
                >
                  {tattoo.desc}
                </p>
              </div>
            ))
         
          }
        </div>
        <div className={styles.btn_add_container}>
          <button
            type="button"
            onClick={() =>
              navigate("/admin/album/addMix")
            }
            className={styles.btn_add + " btn btn-primary p-2"}
          >
            Aggiungi immagine
          </button>
        </div>
      </div>
    </>
  );
};

