import React from 'react'

const Footer = ({ infoFooter }) => {

  
  return infoFooter &&  (
    <footer className="footer">
    <div className="inner-footer inner-footer--flex padding-140">
        <div className="footer-items-container">
                  <p className="copyright">Black Rose Tattoo - <span className="footer-date-year">{new Date().getFullYear()}</span></p>
        </div>
        <div className="footer-items-container footer-icons-socialNetwork">
            {/* <!--whatsapp--> */}
            <a href={`https://api.whatsapp.com/send?phone=39${infoFooter.tel.toString().replace(/[-\s]/g, '')}`} title="Scrivici su whatsapp!">{}</a>
             {/* <!--instagram--> */}
            <a href={infoFooter?.instagram} title="Visita la nostra pagina Instagram Black Rose Tattoo">{}</a>
             {/* <!--facebook--> */}
            <a href={infoFooter?.facebook} style={{cursor:'pointer'}} title="Visita la nostra pagina Facebook Black Rose Tattoo">{}</a>
        </div>
        <div className='footer-items-container '>
           <p><a className='p_privacy' href='/politica_sulla_privacy.pdf'>Politica sulla privacy</a></p> 
        </div>
        <div className='footer-items-container '>
            <p className="p-iva">Partita IVA {infoFooter?.iva_number}</p>
        </div>
       
       
    </div>
</footer>
  )
}

export default Footer
