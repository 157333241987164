import React, { useEffect,useContext } from 'react'

import { Navigate, Outlet } from 'react-router-dom'
import api from '../../Axios/Axios'
import { loginContext } from '../../App'



const ProtectedRoute = ({checkTrigger}) => {
    const {isLogged,setIsLogged} = useContext(loginContext)
   
    useEffect(()=>{

            api.post("user/check","",{
                withCredentials:true,
            })
            .then(res => {
               
             if(res.data?.status === 1){
                 setIsLogged(true)
               
             }else{
                setIsLogged(false)
                 document.location.href = "/"
                
             }
            })
    },[checkTrigger])

    if (isLogged ) {
        return (
            <div>
                <Outlet/>
            </div>
        )
    }else{
        setTimeout(() => {
          return <Navigate to={"/"}/>
        }, 1000);
    }
  
}

export default ProtectedRoute
