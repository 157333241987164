import React, { useContext, useState,useRef, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '../../../../Axios/Axios'
import { modalContext } from "../../PublicRoute";




const SectionStudio = () => {
    const {setModalPath} = useContext(modalContext)
    const [showMore,setShowMore] = useState(false)
    const refTop = useRef()
    const [screenWidth,setScreenWidth] = useState(null)
    
  const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["studioImages"],
    queryFn: async () => {
    return api.get("gallery/studio").then(response => response.data.studio)
    }
  })

 useEffect(()=>{
    window.addEventListener("resize",()=> {
        setScreenWidth(window.innerWidth)
 })

 return () =>   window.removeEventListener("resize",()=> {
    setScreenWidth(window.innerWidth)
})
 },[])


    return (
    <section className="page" id="studio">
    <section className="inner-page-text-section padding-140">
        <p className="title-page">STUDIO</p>
        <h2 className="description-title-page">Il nostro studio</h2>
        <hr className="separator-in-page" />
        <div className="btn-utility-gallery"></div>
    </section>
    <section className="studio-section-desc-img padding-left-140">
        <div className="studio-desc-img-inner">
            <div className="studio-container studio-container--flex">
                <section className="studio-gallery-section">
                    <div className="studio-gallery-inner">
                        <div className="studio-gallery-container">
                            <ul className="studio-gallery-slider studio-gallery-slider--grid">
                                <li className="studio-card-container grid-1">
                                    <div className="studio-card studio-card-desc">
                                        <p className="studio-card-text">
                                            Siete alla ricerca di un fantastico studio di tatuaggi?
                                            Non cercate oltre! I nostri artisti esperti e di talento
                                            lavoreranno con voi per creare un disegno unico che rifletta
                                            il vostro stile individuale.
                                        </p>
                                        <a href='#info' className="btn-underTitle btn-underTitle-studio-section">PRENOTA UN APPUNTAMENTO</a>
                                    </div>
                                </li>
                                        
                                {
                                    data && data.map((img, index) =>
                                    <li key={index}  ref={(ref)=> refTop.current = ref} className={`studio-image-list grid-${index + 2} ${img.type === 1 ? "studio-img-vtl":"studio-img-hrz"} ${index > 3 && !showMore &&  "img-hidden"}`}>
                                            <img
                                                src={`/assets/images/uploads/studio/${img.path}`}
                                                alt={"Foto dello Studio Tattoo Black Rose di Messina "}
                                                style={{objectFit:"cover"}}
                                                onClick={()=>setModalPath({data,index})}
                                            />
                                        </li>
                                    
                                    )
                                
                                } 
                            </ul>
                            <p hidden={screenWidth > 1024}    className="btn-underTitle btn-underTitle-studio-section btn-show-studio-img hidden-LG" onClick={(e)=>{ 
                               
                                if (showMore) {
                                    window.scrollTo({
                                        top: e.pageY /2 ,
                                        behavior:"instant" 
                                    })
                                }
                              
                                setShowMore(!showMore)
                                }} id="btn-handle-showpics">{showMore ? "CARICA MENO FOTO" : "CARICA ALTRE FOTO"}</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </section>
</section>

    
  )
}

export default SectionStudio
