import React, { useEffect, useState,useRef } from 'react'
import api from '../../../../Axios/Axios'
import { FormNotification } from './FormNotification/FormNotification'
import { useNavigate } from 'react-router-dom'
import { FormErrorNotification } from './FormNotification/FormErorrNotification'
import { getImageSize } from 'react-image-size'
import FileResizer from 'react-image-file-resizer'
import FormLoading from './FormNotification/FormLoading'
import RecaptchaModale from './FormNotification/RecaptchaModale'
import ReCAPTCHA from 'react-google-recaptcha'

 const Form = () => {
    const [name,setName] = useState("")
    const [email, setEmail] = useState("")
    const [date, setDate] = useState("")
    const [time, setTime] = useState("")
    const [message,setMessage] = useState("")
    const [image,setImage] = useState(null)
    const [isSubmitted,setIsSubmitted] = useState(false)
    const [formErrors,setFormErrors] = useState([])
    const [submissionCount, setSubmissionCount] = useState(0);
    const  timeoutId = useRef(null);
    const [csrfToken, setCsrfToken] = useState(null);
    const [progress, setProgress] = useState(0);
    const [verificationEmail, setVerificationEmail] = useState("");
    const [recaptchaModale, setRecaptchaModale] = useState(false);
    const recaptchaRef = useRef();
    const [rcpValue,setRcpValue] = useState(null)

useEffect(() => {
    api.post('mailer/get-csrf-token').then(response => {
        setCsrfToken(response.data.csrfToken);
    });
}, []);


    async function sendMail(){
        let recaptchaToken = recaptchaRef.current.getValue();
        const formDatarecp = new FormData();
        formDatarecp.append('recaptcha_token', recaptchaToken);
        api.post('mailer/verifyrecaptcha', formDatarecp,{
           
        }).then(async (res )=> {
            if (res.data.status === 1) {
                setRecaptchaModale(false)
                if (!csrfToken) {
                    setFormErrors(["Errore durante l'invio del messaggio (code -1)"])
                }
        
                if (email !== verificationEmail) {
                    setFormErrors(["L'email non corrisponde, riprova."])
                    return
                }
        
                if (submissionCount > 1) {
                    setFormErrors(["Impossibile inviare il messaggio, riprova tra un minuto"])
                    return
                }else{
                    setSubmissionCount(submissionCount + 1);
                }
                setTimeout(() => setSubmissionCount(0), 60000);
               
                const dateL = new Date(date).toLocaleDateString("it-IT")
                const formdata = new FormData()
                formdata.append("name",name)
                formdata.append("email",email)
                formdata.append("date",dateL)
                formdata.append("time",time)
                formdata.append("message",message)
                formdata.append('csrf_token', csrfToken);
                if (image) {
                    formdata.append("image",await resizeFile(image))
                }
                
                api.post("mailer/send",formdata,{
                    onUploadProgress: function(progressEvent) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted);
                    }
                })
               
                .then(res =>{
                    if (res.data.status === 1) {
                        setIsSubmitted(true)
                        setName("")
                        setEmail("")
                        setDate("")
                        setTime("")
                        setMessage("")
                        setImage(null)
                        setVerificationEmail("")
                    }else{
                        setFormErrors(["Errore durante l'invio del messaggio"])
                    }
                }).catch(()=>{
                    setFormErrors(["Errore durante l'invio del messaggio"])
                })
                .finally(()=>{
                 
                    if(timeoutId.current) {
                        clearTimeout(timeoutId.current); // Clear the previous timeout
                    }
                    timeoutId.current = setTimeout(() => { // Update the timeout ID
                        setProgress(0)
                        closeNotification()
                    }, 3000);
                })
            }else{
                setFormErrors(["Impossibile inviare il messaggio, riprova tra un minuto"])
            }
        })
       
       
    }



    const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        1080,
        1920,
        "WEBP",
        file.size < 3000000 ? 80 : 70,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  


   async  function checkErrors(e){
        e.preventDefault()
        setIsSubmitted(false)
        setFormErrors(false)
        closeRecaptcha()
        const errors = [];
        const now = new Date()
        const newDate = new Date(date +"T"+time)
        const nameIsValid = /^\D+$/.test(name) 
        const emailIsValid = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim.test(email) 
        const dateIsValid = /^\d{4}[-\/]\d{2}[-\/]\d{2}$/.test(date)
        const dateIsNotBeforeToday = newDate.getTime() >=  now.getTime() ? true : false
        const timeIsValid = /^([0-1]\d|20|21|22|23):[0-5]\d$/.test(time)
        if (image) {
            console.log(checkImage(image,errors));
             if (await checkImage(image,errors) !== true) {
                const newErrors = checkImage(image,errors);
                errors.join(newErrors)
                 setImage(null)
             }
        }
        const newError = (error) => {
            errors.push(error)
        } 
        const timeIsNotBeforeNow = ()=>{
            const HoursNow = now.getHours()
            const HoursNext = newDate.getHours()
            const minutesNow = now.getMinutes()
            const minutesNext = newDate.getMinutes()
            if (dateIsNotBeforeToday ) {
                if (now.toLocaleDateString() === newDate.toLocaleDateString()) {
                    if (HoursNow <= HoursNext) {
                        return true
                    }else{
                        newError("L'orario non è corretto, prova a cambiare l'orario.")
                    }if ( minutesNow <= minutesNext) {
                        return true
                    } else {
                        newError("L'orario non è corretto, prova a cambiare l'orario.")
                    }
                }
                return true
            }else{
                if (now.toLocaleDateString() !== newDate.toLocaleDateString()) {
                    newError("La data non è corretta, prova a cambiare la data.")
                }else{
                    newError("L'orario non è corretto, prova a cambiare l'orario.")
                }
            
            }
        }       
        if (!nameIsValid ) newError("Il nome deve contenere solo lettere.")
        if (!name || name.length < 1 ) newError("Il nome é obbligatorio.")
        if (name.length > 21) newError("Il nome può avere massimo 20 caratteri.")
        if (!emailIsValid ) newError("l'email non è valida.")
        if (!dateIsValid) newError("La data non è in un formato valido.")
        if (!timeIsValid) newError("L'orario non è in un formato valido.")
        timeIsNotBeforeNow()
        if (!message || message.length < 4) newError("Il messaggio è obbligatorio e deve avere più di 3 caratteri.")
        if (message.length > 255  ) newError("Il messaggio può avere massimo 255 caratteri.")
      
        if (errors.length < 1) {
            setRecaptchaModale(true)
        }else{
            setFormErrors(errors)
        }
      
    }


    function showErrors(){
        return (
            <div>
                <ul style={{color:"white",padding:0}}>
                    {formErrors?.map((error,index) => 
                    <li className="" key={index}>
                        - {error}
                    </li>)}
                </ul>
            </div>
        )
    }


    function closeNotification(){
       
        setIsSubmitted(false)
        setFormErrors(false)
    }

    


    async  function checkImage(image,errors){
        const imageErrors = [];
            const AcceptedImageTypes = ["image/jpeg","image/png"]

            if (!AcceptedImageTypes.includes(image.type)) {
                errors.push("Il formato immagine deve essere JPEG o PNG")
                imageErrors.push("type")
               
            }

            const dimension = await getImageSize(URL.createObjectURL(image))
            if (dimension.width <= 0 || dimension.height <= 0) {
              errors.push("L'immagine non è valida")
              imageErrors.push("dimension")
           
            }
           
      
           const maxSize = 8 * 1024 * 1024
      
           if (image.size > maxSize || image.size <= 0) {
                errors.push("L'immagine deve avere una grandezza massima di 10MB")
                imageErrors.push("bigImage")
              
           }
           if (imageErrors.length > 0) {
                errors.push("asdasd")
                return imageErrors
           }
         
           return true;
      }


      function checkNewImageSize(file){
        if (file.size < 8000000) {
            return true;
        }else{
            setFormErrors(["L'immagine deve avere una grandezza massima di 8MB"])   
            return false;
        }
      }

      function closeRecaptcha(){
            setRcpValue(null)
          setRecaptchaModale(false)
          setVerificationEmail("")
      }


    
  

    
  return (
    <>
   
    <div>
       { isSubmitted && !formErrors &&  <FormNotification onClick={()=> closeNotification()}/>}
          { formErrors.length > 0 &&  <FormErrorNotification onClick={()=> closeNotification()} errors={showErrors()}/>}
    </div>

    <div>
      <FormLoading progress={progress} />
    </div>
    
    
    <div>
    
      { recaptchaModale &&  <RecaptchaModale  rcpValue={rcpValue} verificationEmail={verificationEmail} setVerificationEmail={setVerificationEmail} closeRecaptcha={()=>closeRecaptcha()}  send={()=>sendMail()} rcp={<ReCAPTCHA  ref={recaptchaRef} onChange={(val)=>setRcpValue(val)} sitekey={"6LdQu04pAAAAAF76OA_o8_35f3I3m6qrMH-FbpZs"} size='normal'/>} />}
    </div>
  
    <form action="#" className="form-contact" id="form-contact" encType="multipart/form-data" onSubmit={(e)=>checkErrors(e)}>
    <div className="form-header-inputs">

        <div className="form-header-top inputs-container">
            <input type="text" name="input-username" value={name.slice(0,20)} onChange={(e)=>{
                 closeNotification()
                setName(e.target.value)}} id="input-username" required  placeholder="Nome" className="input"  maxLength="20"/>
            <div className="inpt-date-container inpt-container--relative">
                <label htmlFor="input-date">{date ? new Date(date).toLocaleDateString() : "Scegli il giorno"}</label>
                <input type={"date"}value={date} onChange={(e)=>{
                    closeNotification()
                    setDate(e.target.value)}
                    } name="input-date" id="input-date" required  className="input"/>
            </div>
        </div>

        <div className="form-header-bottom inputs-container">
            <input type="email" value={email} onChange={(e)=>  
                {closeNotification() 
                setEmail(e.target.value)}} name="input-email" id="input-email" required className="input" placeholder="E-mail"/>
            <div className="inpt-date-container inpt-container--relative">
                <label htmlFor="input-hour">{time ? time : "Scegli l'orario"}</label>
                <input type="time" value={time} onChange={(e)=>{ 
                    closeNotification() 
                    setTime(e.target.value)}} name="input-hour" id="input-hour" required className="input"/>
            </div>
        </div>
    </div>

    <div className="form-middle-textarea">
        <textarea name="input-message" value={message} onChange={(e)=>{
             closeNotification()
            setMessage(e.target.value.slice(0,255))}} id="input-message" cols="30" rows="10" required placeholder="Scrivi un messaggio" maxLength="255"></textarea>
    </div>

    <div className="form-footer-inputs  bottom-inputs-form-contact">
        <label htmlFor="input-file"className="input"> 
                  <div className="upload-img" id="upload-container">
                 {  image && <img style={{borderRadius:4}} src={image && URL.createObjectURL(image)} alt="" />}
                  </div>
                 Allega immagine (Max 8MB) 
        </label>
       
        <input type="file" onChange={(e)=>{
             closeNotification()
            if(checkNewImageSize(e.target.files[0])){
                setImage(e.target.files[0])
            }   
         }} name="input-file" id="input-file" accept="image/jpeg, image/png"/>
        <input type="submit" disabled={isSubmitted} value="PRENOTA APPUNTAMENTO" className="input" id="submit"/>
    </div>
</form>
</>
  )
}

export default Form
