import React, { useCallback, useState } from 'react'
import CarouselBtn from '../../Buttons/CarouselBtn'
import MainCarousel from './MainCarousel'
import { useQuery } from '@tanstack/react-query'
import api from '../../../../Axios/Axios'
import Carousel from '../Tatuatori/Carousel'

const SectionMainGallery = () => {
  

  const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["main"],
    queryFn:() => {
    return api.get("gallery/mix").then(response => response.data.mix)
    },
    refetchOnWindowFocus: false,
  })

  const [pressedBtn, setPressedBtn] = useState(null)
  
  const move = (direction) => {
    if (!pressedBtn) {
      setPressedBtn(true)
      setPressedBtn(direction)
      setTimeout(() => {
        setPressedBtn(false)
      },300)
    }
   
  }
  

  if (data && data.length > 0) {
    return (
      <section className="page" id="galleria">
      <div className="inner-gallery-page inner-page">
          <section className="inner-page-text-section padding-140">
                <p className="title-page">GALLERIA</p>
                <h2 className="description-title-page">Guarda i nostri lavori</h2>
                    <hr className="separator-in-page" />
                    <div className="btn-utility-gallery">
                        <CarouselBtn move={move} />
                    </div>
          </section>
              
          <Carousel albumName={"main/"} isMainCarousel={true} data={data} pressedBtn={pressedBtn} />
      </div>
    </section>
  )
  }
  
}

export default SectionMainGallery
