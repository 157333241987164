import React, {  useContext } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import NewAuthor from './screens/Authors/NewAuthor/NewAuthor'
import Home from './screens/Home/Home'
import Authors from './screens/Authors/Authors'
import GeneralSetting from './screens/GeneralSetting/GeneralSetting'
import OpeningTimes from './screens/OpeningTimes/OpeningTimes'
import OpeningTimesEdit from './screens/OpeningTimes/OpeningTimesEdit'
import GeneralSettingEdit from './screens/GeneralSetting/GeneralSettingEdit'
import SingleAuthor from './screens/Authors/SingleAuthor/SingleAuthor'
import { EditStudioAlbum } from './screens/Albums/EditStudioAlbum'
import { loginContext } from '../../App'
import AuthorAlbum from './screens/Authors/AuthorAlbum/AuthorAlbum'
import NewTattooForm from './screens/Authors/AuthorAlbum/NewTattooForm'
import { EditArtistTatoo } from './screens/Authors/AuthorAlbum/EditArtistTatoo'
import { Albums } from './screens/Albums/Albums'
import { AlbumMix } from './screens/Albums/AlbumMix'
import { NewMixTattoo } from './screens/Albums/NewMixTattoo'
import { EditAlbumMix } from './screens/Albums/EditAlbumMix'
import api from '../../Axios/Axios'
import { NotFoundPage } from './screens/NotFoundPage'



const Admin = ({checkTrigger,setCheckTrigger}) => {
const navigate = useNavigate()
const {isLogged,setIsLogged} = useContext(loginContext)

function logout(){
  api.post("user/logout")
  .then(res => {
    if (res.data.status === 1) {
      setIsLogged(false)
      navigate("/",{replace:true})
    }else{
      alert("Impossibile effetuare il Logout a causa di un errore, riprovare");
    }
  }).catch(()=> navigate("/"))
}

  return isLogged ? (
    <div className='admin'>
        <div  onClick={()=>{
          logout()
        } } 
        className='btn btn-link bg-black btn-sm' 
        style={{position:"absolute",left:10,top:20}}
        >Logout
        </div>
        
      <Routes>
        <Route path='/home' element={<Home />} />
        
        <Route path='/authors/newAuthor' element={<NewAuthor/>} />
        <Route path='/authors' element={<Authors/>} />
        <Route path='/author/:id' element={<SingleAuthor />} />
        <Route path='/author/album/:id' element={<AuthorAlbum />} />
        <Route path='/author/album/add' element={<NewTattooForm/>} />
        <Route path='/author/album/editTattoo' element={<EditArtistTatoo/>} />
        <Route path='/general-settings' element={<GeneralSetting/>} />
        <Route path='/general-settings/edit' element={<GeneralSettingEdit/>} />
        <Route path='/albums' element={<Albums/>} />
        <Route path='/album/mix' element={<AlbumMix/>} />
        <Route path='/album/addMix' element={<NewMixTattoo/>} />
        <Route path='/album/mix/edit' element={<EditAlbumMix/>} />
        <Route path='/album/studio' element={<EditStudioAlbum/>} />
        <Route path='/opening-times' element={<OpeningTimes/>} />
        <Route path='/opening-times/edit' element={<OpeningTimesEdit/>} />
        <Route path='/*' element={<NotFoundPage/>} />
        <Route path='/404' element={<NotFoundPage/>} />
        </Routes>   
     
    </div>
  ):
  <>
    <Navigate to={"/"} replace={true}/>
  </>
  
}

export default Admin
