import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

export const Image = (
    {
      index,
      img,
      data,
      checkImage,
      setImgSize,
      imgSizeISDefined,
      setImgSizeIsDefined,
      nameInCard,
      isMouving,
      setModalPath,
      handleshowModal,
      albumName
    }
  )=>{

    /*Variables and Functions*/
   const line = useRef() 
   const [isShowingDescOnMobile,setIsShowingDescOnMobile] = useState(false)
  
   useEffect(()=>{
    if (isMouving ) {
      setIsShowingDescOnMobile(true)
    }
  },[isMouving,isShowingDescOnMobile])
  
  
    const handleShowDescOnMobile = (e)=>{
    e.stopPropagation()
    setIsShowingDescOnMobile(!isShowingDescOnMobile)
    }



    /* Return */
  
    return (
      <figure
      key={index}
      className={`img-container--slider-tatuatori img-container-tatuatori--first`}
      onClick={(e) =>  handleshowModal({ data, index,albumName })}
    >
  
    { isMobile && (img.title || img.desc) && 
    <div className="btn-mobile-showText-container"   
    role="button"
    onClick={(e)=> isMobile && handleShowDescOnMobile(e)}
  
    >
      
        <div className="btn-mobile-showText">
          <span></span>
        { !isShowingDescOnMobile &&   <span ref={line} className="btn-mobile-showText-second-line"></span>}
        </div> 
    </div>
     
    }
  
      <img
        className="object-fit--cover"
        onError={() => checkImage(index)}
        
        onLoad={(e) => {
          !imgSizeISDefined && setImgSize(e.target.width);
          setImgSizeIsDefined(true);
        }}
        src={"/assets/images/uploads/" + albumName + "/" + img.path}
        alt={
          img.desc ??
          " Un altro bellissimo lavoro realizzato da " +
            nameInCard[0]?.toUpperCase() +
            nameInCard.slice(1).replaceAll(",", "").toLowerCase() +
            ", targato Black Rose Studio. Chiamaci per scoprire il tatuaggio che più si adatta al tuo stile personale!"
        }
        width={397}
        height={560}
       
        loading='lazy'
      />
      
     <figcaption className={`${!isMobile && "img-tatoo-description--hover"} ${isShowingDescOnMobile && isMobile && "img-tatoo-description--hover-mobile" }`}>
        <div className={`hover-background`}></div>
      { !isMobile  ? 
      <>
        <section className={ "icon-details-container--hover"}>
          <div className="icon-card--hover"></div>
          <p className="details-card--hover"></p>
        </section>
        <section className="description-card-container--hover">
          <p className="hashtag-card--hover">{img.title === "null" || img.title === null ? "" : img.title}</p>
          <p className="description-card--hover">{img.desc === "null" || img.desc === null ? "" : img.desc }</p>
        </section>
      </>
      :
      isMobile && isShowingDescOnMobile && 
      <>
      <section className={ "icon-details-container--hover"}>
        <div className="icon-card--hover"></div>
        <p className="details-card--hover"></p>
      </section>
      <section className="description-card-container--hover">
        <p className="hashtag-card--hover">{img.title === "null" || img.title === null ? "" : img.title}</p>
        <p className="description-card--hover">{img.desc === "null" || img.desc === null ? "" : img.desc }</p>
      </section>
    </>
  
      }
      </figcaption>
     
    </figure>
  
    )
  }
