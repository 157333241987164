import React, { useEffect, useState } from "react";
import api from "../../../../../Axios/Axios";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import styles from "./authorAlbum.module.css";
import Title from "../../../Reusables/Title/Title";

const AuthorAlbum = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [success, setSuccess] = useState("");

  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["artist_album"],
    queryFn: () => {
      return api
        .post("artistTattoo/album", { id: id })
        .then((response) => response.data.tattoos);
    },
  });

  

  function deleteTattoo(id, lastPath, albumName,index) {
    try {
      const question = window.confirm(
        "Vuoi veramente eliminare questo tattoo?"
      );
      if (question === false) {
        return;
      }
      const formData = new FormData();
      formData.append("id", id);
      formData.append("lastPath", lastPath);
      formData.append("albumName", albumName);
      api
        .post("artistTattoo/delete", formData)
        .then((res) => {
          if (res.data.status === 1) {
            setSuccess("Eliminato con successo");
            delete data[index]
          } else {
            throw new Error("Impossibile eliminare questo Tattoo");
          }
        })
        .catch((error) => {
          setErrors(error.message);
        });
    } catch (error) {
      setErrors(error.message);
    }
  }

  useEffect(() => {
    if (success) {
      const timeout = setTimeout(() => {
        setSuccess("");
      }, 2000);
      return () => timeout.unref;
    }

    if (errors) {
      const timeout = setTimeout(() => {
        setErrors("");
      }, 4000);
      return () => timeout.unref;
    }
  }, [success, errors]);

  return (
    <>
      <Title title={`Galleria di ${location.state.artistName}`} />
      {errors && <div className=" alert alert-danger">{errors.toString()}</div>}
      {success && (
        <div className=" alert alert-success">{success.toString()}</div>
      )}
      <div className={styles.page_container}>
        <Link className="p-5" to={-1}>
          Indietro
        </Link>
        {(error || !data) && (
           <div  className='fw-bold position-absolute mt-3  alert w-100 alert-danger text-center'>Non ci sono immagini</div>
        )}
        <div className={styles.carousel + " d-flex mt-5 "}>
          {data?.length > 0 &&
            data.map((tattoo, i) => (
              <div key={i} className={`position-relative ${styles.hoverable}`}>
                <div className={styles.icons_container}>
                  <span
                    onClick={() =>
                      navigate("/admin/author/album/editTattoo", {
                        state: {
                          id: tattoo.id,
                          albumName: location.state.albumName,
                          title: tattoo.title,
                          desc: tattoo.desc,
                          imgPath:
                            "/assets/images/uploads/" +
                            location.state.albumName +
                            "/" +
                            tattoo.path,
                          lastPath: tattoo.path,
                        },
                      })
                    }
                  >
                    <img
                      className={styles.icon}
                      src="/assets/icons/edit.svg"
                      alt=""
                    />
                  </span>
                  <span
                    onClick={() =>
                      deleteTattoo(
                        tattoo.id,
                        tattoo.path,
                        location.state.albumName,
                        i
                      )
                    }
                  >
                    <img
                      className={styles.icon}
                      src="/assets/icons/delete.svg"
                      alt=""
                    />
                  </span>
                </div>
                <h5
                  className={styles.title}
                  style={
                    tattoo.title && tattoo.title !== "null"
                      ? {}
                      : { display: "none" }
                  }
                >
                  #{tattoo.title}
                </h5>
                <img
                  
                  src={
                    "/assets/images/uploads/" +
                    location.state.albumName +
                    "/" +
                    tattoo.path
                  }
                  alt=""
                />
                <p
                  className={styles.desc}
                  style={
                    tattoo.desc && tattoo.desc !== "null"
                      ? {}
                      : { display: "none" }
                  }
                >
                  {tattoo.desc}
                </p>
              </div>
            ))
         
          }
        </div>
        <div className={styles.btn_add_container}>
          <button
            type="button"
            onClick={() =>
              navigate("/admin/author/album/add", {
                state: { id, albumName: location.state.albumName },
              })
            }
            className={styles.btn_add + " btn btn-primary p-2"}
          >
            Aggiungi immagine
          </button>
        </div>
      </div>
    </>
  );
};

export default AuthorAlbum;
