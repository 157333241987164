import React from 'react';
import ReactDOM from 'react-dom/client';
import './sass/main.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   
        <Router>
            <App />
        </Router>

);


reportWebVitals();





