import React,{createContext, useState} from 'react'
import { QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { Routes,Route} from 'react-router-dom'
import PublicRoute from './components/publicRoute/PublicRoute'
import Admin from './components/admin/Admin'
import ProtectedRoute from './components/admin/ProtectedRoute';
import Login from './components/admin/screens/Login/Login';


import { NotFoundPage } from './components/admin/screens/NotFoundPage';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const loginContext = createContext(null)

const App = () => {
 
const [isLogged,setIsLogged] = useState(false)
const [checkTrigger,setCheckTrigger] = useState(null)

  

return (
  <>
    <QueryClientProvider client={queryClient} >
        <loginContext.Provider value={{isLogged,setIsLogged}} >
          <Routes>
            {/* //Public Routes */}
            <Route exact path="/"  element={<PublicRoute />} />
            <Route path="*" element={<NotFoundPage/>} />
            <Route path="/admin" element={<Login />} />

            {/* Protected Routes */}
            <Route element={<ProtectedRoute  checkTrigger={checkTrigger} />}>
              <Route path="/admin/*" element={<Admin setCheckTrigger={setCheckTrigger} checkTrigger={checkTrigger} />} />
            </Route>
          </Routes>
        </loginContext.Provider>
    
    </QueryClientProvider>
  </>
);
}

export default App
