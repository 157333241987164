import axios from "axios";


const api =  axios.create({
   withCredentials: true,
   baseURL: process.env.REACT_APP_API,
   headers:{
      "X-Requested-With": "XMLHttpRequest"
   }
});

api.interceptors.response.use((response)=>{
   return response
},function (error){
   if (error.response.status !== 200) {
         document.location.pathname = "/404"
   }
})


export default api