import React, { useState } from 'react'
import {Link, useLocation} from 'react-router-dom'
import Title from '../../../Reusables/Title/Title';
import Resizer from "react-image-file-resizer";
import api from '../../../../../Axios/Axios';
import { Col, FormCheck, FormControl, FormGroup, FormLabel, Row, Toast, ToastContainer } from 'react-bootstrap';
import FormContainer from '../../../Reusables/Form/Form';
import {getImageSize} from 'react-image-size'
import Loading from '../../../Reusables/Loading/Loading';


const SingleAuthor = () => {

  const location = useLocation()
  
  const [loading,setLoading] = useState(false)
  const [image, setImage] = useState(null)
  const [name, setName] = useState(location?.state?.name)
  const [stageName, setStageName] = useState(location?.state?.stage_name)
  const [nameInCard, setNameInCard] = useState(location?.state?.name_in_card)
  const [tel, setTel] = useState(location?.state?.tel)
  const [instagram, setInstagram] = useState(location?.state?.instagram)
  const [facebook, setFacebook] = useState(location?.state?.facebook)
  const [show, setShow] = useState(false)
  const [formErrors, setFormErrors] = useState([])
  const [toastTitle, setToastTitle] = useState("")
  const [lastImage,setLastImage] = useState(location.state?.image_path ?? false)
 
  const onSubmit = async (e) => {
    setLoading(true)
      setFormErrors([])
      setToastTitle("")
      e.preventDefault()
      setShow(true)
      const errors = checkFormInputs();
      if (errors.length > 0) {
          setFormErrors([...errors])
          setLoading(false)
      } else { 

        const formData = new FormData()
        let apiOption = {}
          if (image) {
            const dimension = await getImageSize(URL.createObjectURL(image))
            if (dimension.width > dimension.height) {
              setFormErrors(["L'immagine deve essere verticale"])
              setLoading(false) 
              return
            }
            const newImage = await resizeFile(image)
            if (newImage) {
              apiOption = { "Content-Type": "multipart/form-data" }
              formData.append("image", newImage)
              formData.append("lastImage",location.state.image_path)
            }
          } 
          formData.append("id",location?.state?.id)
          formData.append("name", name);
          formData.append("stageName",stageName)
          formData.append("nameInCard",nameInCard)
          formData.append("tel",tel)
          formData.append("instagram",instagram)
          formData.append("facebook",facebook)
         
        api.post("artist/update", formData, apiOption).then(res => {
          if (res.data?.status === 1) {
            setFormErrors([])
            setToastTitle("Autore modificato")
            setTimeout(() => {
              window.history.back();
            }, 500);
                 
          } else {
            setFormErrors()
            if (res.data.status === 0) {
                 
              setFormErrors(["Impossibile modificare i dati"])
                    
            } else {
              setFormErrors(["Impossibile modificare i dati"])
            }
                 
          }
        }).finally(() => { setLoading(false) })
      }
  }


const checkFormInputs = () => {
  let errors = [];
  const nameIsValid = name.match(/^[A-Za-z0-9]{3,20}$/) 
  const stageNameIsValid = stageName.match(/^[A-Za-z0-9]{3,20}$/)
  const nameInCardIsValid = nameInCard.match(/^[A-Za-z0-9]{1,3}(,[A-Za-z0-9]{1,3}){0,2}$/)
  const telIsValid = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/).test(tel)
  const instaIsValid = instagram.match(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/)
  const facebookIsValid = facebook.match(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/)
  const largeImage = image?.size > 8388608
  const isImageValid = image?.type.match("image/png|image/jpeg|image/jpg")
  
  if (!nameIsValid) {
      errors.push("Il nome non è valido, deve contenere solo lettere o numeri, minimo 3 e massimo 20")
  }
      
  if (!stageNameIsValid) {
      errors.push("Stage Name non è valido, deve contenere solo lettere o numeri, minimo 3 caratteri e massimo 20 caratteri")
  }

  if (!nameInCardIsValid) {
      errors.push("Name in Card non è valido, può contenere solo lettere, numeri e virgole. Esempio : JO,HN,X")
  }

  if (!telIsValid) {
      errors.push("Il numero di telefono non è valido")
  }

  if (!instaIsValid) {
      errors.push("Il link di Instagram non è valido")
  }

  if (!facebookIsValid) {
      errors.push("Il link di Facebook non è valido")
  }

  if (!image && !lastImage) {
      errors.push("L'immagine è richiesta")
  }

  if (image && largeImage && !lastImage) {
      errors.push("Image deve essere massimo di 8Mb")
  }

  if (!isImageValid && !lastImage) {
      errors.push("Il formato dell'immagine non valido, ammessi solo jpeg e png")
  }
  

      return errors
}

const resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    397,
    560,
    "WEBP",
    90,
    0,
    (uri) => {
      resolve(uri);
    },
    "file"
  );
});
  

  
  
  return location?.state?.id &&  (
    <div>
      {loading && <Loading /> }
    <Title title={"Modifica Artista"} />
    <div className='container p-3'>

<Row>
    <Col xs={6}>
        <ToastContainer position='top-center' style={{position:"fixed"}}>
            <Toast  className={formErrors?.length > 0 ? "bg-danger" : "bg-success"} onClose={() => setShow(false)} onClick={()=>setShow(false)} show={show} delay={5000} autohide>
                <Toast.Header>
                   { formErrors?.length > 0 ? <strong className="me-auto">Error</strong>: <strong className="me-auto">Success</strong>}
                </Toast.Header>
            <Toast.Body style={{color:"white"}}>{formErrors.length > 0 ? formErrors.map((err, i) =><div> <p key={i}>- {err}</p> </div> ) : <p>{toastTitle}</p>}</Toast.Body>
            </Toast>
        </ToastContainer>
</Col>

    </Row>
        <div>
        <Link className='p-2 mb-4 d-block' to={"/admin/authors"}>Indietro</Link>
        <FormContainer onSubmit={onSubmit} submitBtnText={"SEND"}>

                <FormGroup className="mb-3" >
                        <FormLabel htmlFor={"name"}>Nome</FormLabel>
                        <FormControl required={true} type={"text"} value={name}  name={"name"} id={"name"} onChange={(e)=>setName(e.target.value)} placeholder={"Scrivi il Nome dell'artista"} />
                </FormGroup> 
                
                <FormGroup className="mb-3" >
                        <FormLabel htmlFor={"stageName"}>Nome d'arte (senza '#')</FormLabel>
                        <FormControl required={true} value={stageName} onChange={(e)=>setStageName(e.target.value)}  type={"text"} name={"stageName"}  placeholder={"Scrivere il nome d'arte senza '#'"} />
                </FormGroup> 
                
                <FormGroup className="mb-3" >
                        <FormLabel htmlFor={"nameInCard"}>Nome nella Card (separare da virgole)</FormLabel>
                        <FormControl required={true} value={nameInCard} onChange={(e)=>setNameInCard(e.target.value)}  type={"text"} name={"stageName"}  placeholder={"Esempio: STE,VE,X"} />
                </FormGroup>

                <FormGroup className="mb-3" >
                        <FormLabel htmlFor={"tel"}>Telefono</FormLabel>
                        <FormControl required={true} value={tel} onChange={(e)=>setTel(e.target.value)}  type={"tel"} name={"tel"} placeholder={"Scrivere il numero di telefono"} />
                </FormGroup>

                <FormGroup className="mb-3" >
                        <FormLabel htmlFor={"tel"}>{"Instagram URL"}</FormLabel>
                        <FormControl required={true} value={instagram} onChange={(e)=>setInstagram(e.target.value)}  type={"url"} name={"instagram"} placeholder={"URL del conto Instagram dell'artista"} />
                </FormGroup> 


                <FormGroup className="mb-3" >
                        <FormLabel htmlFor={"facebook"}>Facebook URL</FormLabel>
                        <FormControl required={true}  value={facebook} onChange={(e)=>setFacebook(e.target.value)}  type={"text"} name={"facebook"} placeholder={"URL del conto Facebook dell'artista"} />
                </FormGroup> 

            <FormGroup className="mb-3" >
                    <FormLabel htmlFor={"image"}>{"Immagine del profile"}</FormLabel>
                    <FormControl disabled={lastImage} style={lastImage ? {opacity:0.1,display:"block"} : {display:"block"}} onChange={(e) => setImage(e.target.files[0])} required={true}  type={"file"} name={"image"} accept='image/jpeg, image/png' />
                    <img className='mt-1 border-1' style={{ width: "160px", height: "200px",border:"1px solid black"}} src={image ? URL.createObjectURL(image) : "/assets/images/uploads/" + location.state.image_path} alt="" />
            </FormGroup> 
            
            <FormGroup className="mb-3 container d-flex gap-2"  >
              <FormLabel htmlFor={"lastImage"}>{"Usa la Stessa Immagine"}</FormLabel>
              <FormCheck onChange={(e) => setLastImage(!lastImage)}  type={"checkbox"} name={"lastImage"} checked={lastImage } />
            </FormGroup> 
            
        </FormContainer>
    </div>  
    
</div>
    </div>
  )
}

export default SingleAuthor
