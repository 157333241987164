import React from 'react'
import api from '../../../../Axios/Axios'
import { useQuery } from '@tanstack/react-query'

const OpeningTimes = () => {

  
const { isLoading,  data, error , refetch } = useQuery({
  queryKey: ["openingTimes"],
  queryFn:() => {
  return api.get("info/openingTimes").then(response => response.data.openingTimes)
  }
})


  return data && (
    <div className="info-container info-container3">
    <p className="info-title">ORARI D'APERTURA</p>

    <div className="info-container-item info-item-container">
        <div className="icon-item icon-item--6"></div>
        <p className="text-info-item text-info-item-openTime">
          {data[0]?.open ? <b>LUN-VEN:<time>{data[0]?.start_at}</time>-<time>{data[0]?.end_at}</time>&nbsp; &nbsp; </b> :  <b>LUN-VEN:<time>{" Chiuso "}</time> </b>}
          {data[1]?.open ? <b>SAB:<time>{data[1]?.start_at}</time>-<time>{data[1]?.end_at}</time>&nbsp; &nbsp; </b> : <> <br/> <b>SAB:<time>{" Chiuso "}</time> </b> </>}
          <br/>
          {data[2]?.open ? <b>DOM:<time>{data[2]?.start_at}</time>-<time>{data[2]?.end_at}</time>&nbsp; &nbsp; </b> :  <b>DOM:<time>{" Chiuso"}</time> &nbsp; &nbsp;</b>} 
        </p>
    </div>

    <p className="info-title info-title-map">DOVE TROVARCI</p>
    
    <a href="https://goo.gl/maps/zdZJBRKq1FKAC7YL9" rel='noreferrer'  target="_blank"  className="map-$">
    <div className="map" > 
      <iframe title="Trovaci su Google Maps" className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3135.4552668554447!2d15.541866392980303!3d38.19932569938028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13144d71d2a7dff7%3A0x73f8435d376fc20f!2sBlack%20rose%20tattoo%20studio!5e0!3m2!1sen!2sfr!4v1701504103333!5m2!1sen!2sfr" width="600" height="450" style={{border:0,width:"100%",zIndex:10,position:"relative"}} allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    </a>
</div>
  )
}

export default OpeningTimes
