import React from 'react'
import styles from "./notification.module.css"

export const FormNotification = ({onClick}) => {

  return (
    <div onClick={onClick} class={`toast show ${styles.f_toast}`} role="alert" aria-live="assertive" aria-atomic="true">
    <div class={`toast-header  d-flex justify-content-between ${styles.notification_header} bg-dark`} style={{border:"none"}}>
    <strong class={`mr-auto ${styles.notification_brand_name}`}>BlackRoseTattoo</strong>
    <button type="button" class={`ml-2 mb-1 close ${styles.notification_close_btn}`} data-dismiss="toast" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
    <div class="toast-body bg-success text-white">
      Grazie per averci contattato!
    </div>
  </div>

  )

}
