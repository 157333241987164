import React, { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import api from '../../../../Axios/Axios'

const StudioInfos = ({getInfoForFooter}) => {
    

    const { isLoading,  data, error , refetch } = useQuery({
        queryKey: ["studioInfo"],
        queryFn:() => {
        return api.get("info/info").then(response => response.data.info)
        }
      })

    useEffect(() => {
      if (data) {
        getInfoForFooter(data) 
      }
    },[data])

  return (
    <div className="info-container info-container2">
                <p className="info-title">INFORMAZIONI UTILI</p>

                <div className="info-container2-item info-item-container">
                    <div className="icon-item icon-item--1"></div>
                    <address className="item-address text-info-item">
                        Black Rose Tattoo Studio - 
                        <br/>
                       {data?.address }
                    </address>
                </div>
                <div className="info-container2-item info-item-container">
                    <div className="icon-item icon-item--2"></div>
                  <a href={"tel:"+ data?.tel} className="text-info-item" title="Numero di telefono Black Rose Tattoo">{data?.tel}</a> 
                </div>

                <div className="info-container2-item info-item-container">
                    <div className="icon-item icon-item--3"></div>
                    <a href={`mailto:${data?.email}`} className="text-info-item text-info-item-link item-email-link" title="La nostra Email">{data?.email}</a>
                </div>

                <div className="info-container2-item info-item-container item-whatsapp">
                    <div className="icon-item icon-item--4"></div>
                    <a href={`https://api.whatsapp.com/send?phone=39${data?.tel.toString().replace(/[-\s]/g, '').replace("+","")}`} className="text-info-item text-info-item-link item-whatsapp-link" title="Contattaci con Whatsapp">@BlackRoseTattoo</a>
                </div>

                <div className="info-container2-item info-item-container item-instagram">
                    <div className="icon-item icon-item--5"></div>
                    <a href={data?.instagram} rel='noreferrer' target="_blank" className="text-info-item text-info-item-link item-instagram-link" title="Visita la nostra pagina Instagram">@BlackRoseTattoo</a>
                </div>
            </div>
  )
}

export default StudioInfos
