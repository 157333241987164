import React, {useEffect,useState} from "react";
import api from "../../../../Axios/Axios";
import CardInfo from "./CardInfo";
import { Image } from "./Image";


const TattooImages = ({
  nameInCard,
  authorInfo,
  setImgSize,
  setModalPath,
  isMouving,
  albumName
}) => {
  const [imgSizeISDefined, setImgSizeIsDefined] = useState(false);
  const [data, setData] = useState([]);
  


  useEffect(() => {
 
      api.get(`artistTattoo/album/` + authorInfo.authorName ).then((response) => {
        setData(response.data.tattoos);
      });
    
  }, []);

  function checkImage(index) {
    setData(data.filter((d, i) => d[i] !== d[index]));
  }

  const  handleshowModal = (({data,index,albumName})=>{
    if (!isMouving ) {
      setModalPath({data,index,albumName: albumName + "/"}) 
    }
  })

  


  return (
    <>
       
      <CardInfo
        tel={authorInfo.tel}
        instagram={authorInfo.instagram}
        facebook={authorInfo.facebook}
        imagePath={authorInfo.imagePath}
        
      />
    

      {data?.map((img, index) => {
        return (
          <Image
          key={index}
          img={img} 
          index={index}
          data={data}
          checkImage={checkImage}
          nameInCard={nameInCard}
          setImgSize={setImgSize}
          setModalPath={setModalPath}
          setImgSizeIsDefined={setImgSizeIsDefined}
          imgSizeISDefined={imgSizeISDefined}
          handleshowModal={handleshowModal}
          albumName={albumName}
          />
        );
      })}
    </>
  );
};

export default TattooImages;


