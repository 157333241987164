import React from 'react'
import Title from '../../Reusables/Title/Title'
import styles from "./albums.module.css"
import { Link } from 'react-router-dom'

export const Albums = () => {
  return (
    <div>
      <Title title={"Gallerie Principali"} />
      <Link className="p-5" to={"/admin/home"}>
          Indietro
        </Link>
         <div className='container d-flex flex-column gap-5 mt-5'>
            <Link className={styles.galleryBtn} to={"/admin/album/mix"}>Galleria MIX</Link>
            <Link className={styles.galleryBtn} to={"/admin/album/studio"}>Galleria Studio</Link>
         </div>
    </div>
  )
}
