import React from "react";
import Title from "../../Reusables/Title/Title";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from '@tanstack/react-query'
import api from "../../../../Axios/Axios";

const OpeningTimes = () => {
  const navigate = useNavigate();
 
  const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["openingTimes"],
    queryFn:() => {
    return api.post("info/openingTimes").then(response => response.data?.openingTimes)
    }
})


  return (
    <div>
      <Title title={"Orari d'apertura"} />
      <Link className="p-5" to={"/admin/home"}>
        Indietro
      </Link>

      <div className="container-fluid d-grid justify-content-center">
        <table className="table table-responsive p-2 slider d-block admin-table">
          <thead className="text-center">
            <tr>
              <th>Giorno</th>
              <th>Inizio</th>
              <th>Fine</th>
              <th>Modifica</th>
              <th>Stato</th>
            </tr>
          </thead>

          <tbody className="text-center">
            {data &&
              data.map((day, i) => {
                return (
                  <tr key={i} >
                   
                    <td className="border-1">{day.day}</td>
                    <td className="border-1">{day.start_at}</td>
                    <td className="border-1">{day.end_at}</td>
                    <td
                      className="border-1 hover-effect"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/admin/opening-times/edit", { state: day })
                      }
                    >
                      <img
                        src="/assets/icons/edit.svg"
                        style={{minWidth:0,width:50}}
                        width={50}
                        alt=""
                      />
                    </td>
                    <td style={day.open === 0 ? {background:"red"} : {background:"Lightgreen"}}>{day.open === 0 ? "chiuso" : "aperto"}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OpeningTimes;
