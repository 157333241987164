import React from 'react';
import './RecaptchaModale.css';

const RecaptchaModale = ({verificationEmail,setVerificationEmail,closeRecaptcha,send,rcp,rcpValue}) => {
    return (
        <div>
            <div className='recaptcha_back '>
               <div className='recaptcha_container position-relative'>
                <div className="float-end position-absolute" style={{left:"85%"}}>
                    <button className='p-1 btn-close btn-close_rcp' onClick={()=>closeRecaptcha()}></button>
                </div>
                <p className='text_rcp_modale'>Verifica l'email <span style={{color:"red"}}>*</span></p>
              
                <input
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            padding: '8px 12px',
                            fontSize: '16px',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                        type="email"
                        value={verificationEmail}
                        onChange={(e) => setVerificationEmail(e.target.value)}
                        name="input-VerificationEmail"
                        id="input-VerificationEmail"
                        required
                        className="bg-light input"
                        placeholder="Riscrivi la tua email"
                    />
                   <p className='text_rcp_modale'>Verifica <strong>Recaptcha</strong> <span style={{color:"red", }}>*</span></p>
                   <div>
                    <div>
                        {rcp}
                    </div>
                    <button className='btn btn-dark' disabled={!rcpValue} onClick={()=>send()}>
                        Invia
                    </button>
                   </div>
               </div>
               
            </div>
        </div>
    );
};

export default RecaptchaModale;
