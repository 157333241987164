
import React, { useEffect, useState } from 'react'
import { Link} from 'react-router-dom'
import {getImageSize} from 'react-image-size'
import Resizer from "react-image-file-resizer";
import api from '../../../../Axios/Axios';
import Title from '../../Reusables/Title/Title';
import Loading from '../../Reusables/Loading/Loading';
export const NewMixTattoo = () => {

    const [file,setFile] = useState(null)
    const [title,setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [errors,setErrors] = useState(null)
    const [success,setSuccess] = useState("")
    const [multipleFiles, setMultipleFiles] = useState([])
    const [loading,setLoading] = useState(false)
  

     async function submit(e) {
       e.preventDefault() 
       setLoading(true)
       
        if (checkForErrors() !== true) {
          setErrors(checkForErrors().toString())
          setLoading(false)
          return
        }
        let array = []
        if (multipleFiles.length > 0) {
        for (let i = 0; i <  multipleFiles.length; i++) {
          const ResizedImage = await resizeFile(multipleFiles[i]);
           array.push(ResizedImage)

          if (i === multipleFiles.length - 1) {
           
            sendImages(e,array) 
          }
        }
      }
  }     

    function sendImages(e,array){
      const formData = new FormData()
     
      array.forEach(element => {
        formData.append("file[]",element)
      });
    
      formData.append("title",title)
      formData.append("desc",desc)

      if (array.length === multipleFiles.length) {
        api.post("gallery/addMix",formData)
        .then(res => {
          if (res.data.status === 1) {
            if(multipleFiles.length > 1){
              setSuccess("Nuovi Tattoos aggiunti con successo");
            }else{
              setSuccess("Nuovo Tattoo aggiunto con successo");
            }
              setErrors(null)
              setMultipleFiles([])
              e.target.value = ""
              e.target.files = []
          } else {
            setLoading(false)
            setErrors("Errore, non è stato possibile eseguire la richiesta");
          }
        }).finally(() => {
          setFile(null)
          setTitle("")
          setDesc("")
          e.target.value = ""
          e.target.files = []
          setLoading(false)
        })
      }else{
        setErrors("Errore, non è stato possibile eseguire la richiesta");
        setLoading(false)
        e.target.value = ""
        e.target.files = []
    }
  }



    useEffect(()=>{
      if (success) {
        const timeout = setTimeout(() => {
            setSuccess("")
        }, 2000);
        return ()=> timeout.unref
      }

    },[success])


    function checkForErrors(){
      let errors = [];
      if (!file && !multipleFiles) {
       errors.push("L'immagine è obbligatoria");
      }
      if (title && title.length > 25 ) {
        errors.push("Il titolo deve essere di massimo 25 caratteri");
      }

      if (desc && desc.length > 255 ) {
        errors.push("La descrizione deve essere di massimo 255 caratteri");
      }

      if (errors.length > 0) {
        return errors
      }
      return true
    }



     function checkImage(input){
    
    try {
      let errorsImage = [];
      const images =[ ...input.files]

      if (images.length > 0) {
        if (images.length > 10) {
          setErrors("Puoi Inviare solo 10 immagini alla volta")
        }
        images.forEach(async (image,i) => {
          
          const AcceptedImageTypes = ["image/jpeg","image/png"]
          const dimension = await getImageSize(URL.createObjectURL(image))
          if (dimension) {
            if (dimension.width <= 0 || dimension.width > dimension.height) {
              input.value = ""
              errorsImage = ["dimension"];
              setMultipleFiles([])
              setErrors("Il formato dell'immagine deve essere verticale")
            }
          }
         
          if (!AcceptedImageTypes.includes(image.type)) {
           
            input.value = ""
            errorsImage = ["type"];
            setMultipleFiles([])
            setErrors("Il tipo accettato è jpeg e png")
          }
    
         const maxSize = 20 * 1024 * 1024
    
         if (image.size > maxSize || image.size <= 0) {
          
          input.value = ""
          errorsImage = ["size"];
          setMultipleFiles([])
          setErrors("L'immagine deve pesare meno di 20Mb")
         }
  
        
         if (images.length > 0 && errorsImage.length === 0) {
            setMultipleFiles([...images])
            setFile(null)
            setErrors(null);
            
         }
        });
      }else{
        setErrors("L'immagine è obbligatoria")
      }
    } catch (error) {
      setErrors(error.message);
      setFile(null)
      input.value = ""
      setMultipleFiles([])
      return 
    }
  }



  const resizeFile = (file) =>
  new Promise(async(resolve) => {
    let size = 70
    const dimension = await getImageSize(URL.createObjectURL(file))
    if (dimension) {
      Resizer.imageFileResizer(
        file,
        dimension.width,
        dimension.height,
        "WEBP",
        size,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    }else{
      throw new Error("Errore, non è stato possibile eseguire la richiesta");
    }
   
  });

 


  return (
    <div>
      {loading && <Loading/>}
          <Title title={"Aggiungi Mix Tattoo"} />
          {errors && <div className=" alert alert-danger">{errors.toString()}</div>}
          {success && <div className=" alert alert-success">{success.toString()}</div>}
          <Link className='p-5' to={"/admin/album/mix"}>Indietro</Link>
   
          <div className={`container m-auto w-100 d-lg-flex justify-content-center align-content-center`}>
              <form onSubmit={submit} className="container-sm  ">
                    <div className='mt-4'>
                        <label htmlFor="title" hidden className="fw-bold">Inserire un titolo <small className="fw-light"> (massimo 25 caratteri)</small></label> 
                        <input type="text" hidden name="title" id="title" maxLength={25} className="form-control form-text w-100 border-2 w-100" onChange={(e)=>setTitle(e.target.value)} value={title} />
                    </div>     
                    <div className='mt-4'>
                        <label htmlFor="desc" hidden className="fw-bold"> Inserire una descrizione  <small className="fw-light" > (massimo 255 caratteri)</small></label>
                      <textarea type="text" hidden name="desc" id="desc" className="form-control w-50 border-2 w-100" maxLength={255} onChange={(e)=>setDesc(e.target.value)} value={desc}></textarea>
                    </div>    
                    <div className='mt-4'>
                      <label htmlFor="file" className="fw-bold mt-4">Caricare una foto verticale <span className="text-danger">*</span></label>
                      {file  && <div className="w-25"><img src={URL.createObjectURL(file)} className='mb-3' alt="" /></div>}
                        <input type="file" className="form-control-file d-block  w-100" multiple name="file" id="file" accept='image/jpeg, image/png' onChange={(e)=> checkImage(e.target)} required={true}  />
                    </div>

                    {multipleFiles && !file &&
                      <div className='d-flex overflow-y-hidden overflow-x-auto gap-4'>
                        {multipleFiles.map((file,i) => 
                        <div key={i} style={{width:180}}>
                          <img className='w-100 mt-2' src={URL.createObjectURL(file)} alt="" />
                        </div>
                        )}
                      </div>}
                    <button type="submit" name={"submit"}  className="btn btn-primary w-50 mt-5 p-2" style={{maxWidth:300}}>Aggiungi</button>
              </form>
      </div>
    </div>
  )
}


