
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../../Axios/Axios'
import { getImageSize } from 'react-image-size';
import { Notification } from '../../Reusables/Notification';
import FileResizer from "react-image-file-resizer";
import { useQuery } from '@tanstack/react-query';
import Loading from '../../Reusables/Loading/Loading';


export const EditStudioAlbum = () => {
    const [images,setImages] = useState([])
    const [notification,setNotification] = useState({})
  const [isSending, setIsSending] = useState(false)
  const [loading,setLoading] = useState(false)  

    
  const { isLoading,  data, error , refetch } = useQuery({
    queryKey: ["studio_images"],
    queryFn:() => {
    return api.post("gallery/studio").then(response => response.data.studio)
    }
})

 
useEffect(()=>{
    if (data) {
        setImages([...data])
    }
},[data])


    async function handleChangeImage(id,file,type = null,lastPath,index){
        if(!file){
        return
        }
       
        try {
            const dimensions = await getImageSize(URL.createObjectURL(file))
            if (dimensions?.width) {
                if(file.size > 20 * 1024 * 1024){
                    setNotification({status:0,title:"Errore",message:"La foto deve essere minore di 20MB"})
                    throw new Error("La foto deve essere minore di 20MB")
                }else if (type === 0 && dimensions?.width <= dimensions?.height) {
                    setNotification({status:0,title:"Errore",message:"La foto deve essere orizzontale"})
                    throw new Error("La foto deve essere orizzontale")
                
                }else if (type === 1 &&  dimensions?.height <= dimensions?.width){
                    setNotification({status:0,title:"Errore",message:"La foto deve essere verticale"})
                    throw new Error("La foto deve essere verticale")
                }
                const newImage = {
                    file,
                    type,
                    id:id,
                    w:dimensions?.width,
                    h:dimensions?.height
                }
              
                save(newImage,lastPath,index)
            }else{
                setNotification({status:0,title:"Errore",message:"Problema durante la modifica della foto"})
                throw new Error("Problema durante la modifica della foto")
            }
        } catch (error) {
            if (error) {
                console.error("Problema durante la modifica della foto");
            }
             
        }
}


async function save(image,lastPath,index) {
  try {
    
  setLoading(true)
  if (
    !image ||
    !image.file ||
    image.type < 0 ||
    image.id < 0 ||
    !typeof image.file === "object"
  ) {
    setLoading(false)
    return;
  }
 
  const formData = new FormData();
  const notification = {
    status: 0,
    title: "",
    message: "",
  };
  const resizedFile = await resizeFile(image);
  if (resizedFile && image.id) {
    formData.append("file", resizedFile);
    formData.append("id", image.id);
    formData.append("lastPath", lastPath);
    formData.append("type", image.type);
  } else {
    setLoading(false)
    throw new Error(
      "Errore durante la modifica dell'immagine in formato .webp"
    );
  }
  setIsSending(true)
  

  api
    .post("gallery/editStudioTattoo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      if (res.data.status === 1) {
        notification.status = 1;
        notification.title = "Modifica effettuata";
        notification.message = "";
        images[index] = {
            id:image.id,
            path:res.data.path,
            type:image.type
        }
        setImages([...images])
        
      } else {
        notification.status = 0;
        notification.title = "Errore";
        notification.message = "non è stato possibile effettuare le modifiche";
      }
    })
    .finally((res) => {
      setNotification(notification);
      setIsSending(false)
      setLoading(false)
    });

  } catch (error) {
    setLoading(false)
  }
}  


  //  const resizeFile = (file) =>
  //   new Promise((resolve) => {
  //     FileResizer.imageFileResizer(
  //       file.file,
  //       file.w /2 ,
  //       file.y /2,
  //       "WEBP",
  //       50,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //       },
  //       "file",
  //       file.w / 2,
  //       file.y / 2
  //       )
  //   });

    const resizeFile = (file) =>
    new Promise(async(resolve) => {
      let size = 70
      const dimension = await getImageSize(URL?.createObjectURL(file.file))

      if (dimension) {
        FileResizer.imageFileResizer(
          file.file,
          dimension.width,
          dimension.height,
          "WEBP",
          size,
          0,
          (uri) => {
            resolve(uri);
          },
          "file"
        );
      }else{
        throw new Error("Errore, non è stato possibile eseguire la richiesta");
      }
     
    });


  return (
    <>
      {isLoading && <Loading/>}
    <section className="" id="studio" style={{paddingBottom:30}}>
    <div className="container-fluid text-bg-primary text-center h3 p-3 z-0 position-relative"> Immagini Studio</div>
    <p className="text-center h4 pt-3">Cliccare sulla foto per modificarla</p>
    {notification.title && <Notification notification={notification} setNotification={setNotification}/>}
    <div className='container'>
      <Link className="p-2" to={-1}>Indietro</Link>
    </div>
    

    
    <div style={{flexWrap:"wrap",marginTop:40}} className='d-flex flex-row overflow-visible gap-3 justify-content-center'>
   
            {  
               images.map((img, index) =>
             
                <div  style={{alignContent:'flex-end',alignSelf:"center",position:"relative",border:"1px solid black",height: img.type === 0 ? 270: 320}} key={"img_" + index + img.id}>  
                    <label htmlFor={"img_"+index}  style={{position:"absolute",top:0,left:0,height:"100%",width:200,zIndex:10,cursor:"pointer"}}></label>
                    <input type="file" id={"img_"+index} style={{display:"none"}}  onChange={(e)=> e.target.files[0] && handleChangeImage(img.id,e.target.files[0],img.type,img.path,index)} accept='image/jpeg, image/png' />  
                 
                    <img 
                    className="p-2" 
                    src={ "/assets/images/uploads/studio/" + img.path  }
                    alt=""
                  
                    />
                </div>

            )}
        </div>
    </section>
  </>
  )}